* {
  box-sizing: border-box;
}

.room_content {
  border: 2px dashed red;
  width: 100%;
  max-width: 1400px;
  max-height: 70vh;
  height: 68vh;
  padding: 10px;
  display: block;

  box-sizing: border-box;
}
/* .room_content:focus {
  border: 2px dashed red;
  outline: none;
  box-shadow: none;
} */
